// n is number of stars required
@function multiple-box-shadow ($n) {
  $value: '#{random(3440)}px #{random(1440)}px #FFF';

  @for $i from 2 through $n {
    $value: '#{$value} , #{random(3440)}px #{random(1440)}px #FFF'
  }

  @return unquote($value)
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

body {
  overflow: hidden;
}

#background-wrapper {
  height: 100%;
  width: 100%;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
}

#stars {
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}

#stars2 {
  position: absolute;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}

#stars3 {
  position: absolute;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px)
  }

  to {
    transform: translateY(-2000px)
  }
}